<template>
  <div>
    <div class="text-right mb-2">
      <download-json-csv :data="csvItems" :name="csvFileName" :labels="csvHeader" :fields="csvFieldsToInclude">
        <!-- :data="items"/filteredItems :fields="csvFieldsToInclude" -->
        <b-button variant="primary" size="md" type="submit">Download User List</b-button>
      </download-json-csv>
    </div>
    <b-card no-body>
      <!-- User Interface controls -->
      <b-card-header class="bg-light">
        <b-row>
          <b-col md="6" xl="5">
            <b-input-group>
              <b-form-input v-model="filter" class="form-control" placeholder="Search"></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>

          <b-col md="2" xl="2" class="offset-xl-1 col-form-label"><strong>Active: {{active}}</strong></b-col>
          <b-col md="2" xl="2" class="col-form-label"><strong>Inactive: {{inActive}}</strong></b-col>

          <b-col md="2" xl="2">
            <b-form-group label-cols-sm="4" label-cols-xl="6" label="Per page" class="mb-0">
              <b-form-select v-model="perPage" :options="pageOptions" class="form-control"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-header>

      <b-table
        class="card-table"
        show-empty
        hover
        :fields="userTableFields"
        :current-page="currentPage"
        :items="userTableItems"
        :filter="filter"
        :per-page="perPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :sort-compare="sortCompare"
        responsive
        :busy="tableBusy"
        @filtered="onFiltered"
        >
        <template #cell(employee_id)="data">
          <router-link
            v-if="data.item.employee_id"
            :to="`/users/${data.item.id}`">{{ data.item.employee_id }}</router-link>
        </template>
        <template #cell(email)="data">
          <router-link
            v-if="data.item.email"
            :to="`/users/${data.item.id}`">{{ data.item.email }}</router-link>
        </template>
        <template #cell(role)="data">
          {{ getLabel(data.item.role, roles) }}
        </template>
        <template #cell(last_login)="data">
          <i class="fa fa-lock" v-if="data.item.locked"></i>
          {{ data.item.last_login }}
        </template>
        <template #cell(enabled)="data">
          <b-form-checkbox
            v-if="data.item.id !== parsedAccessToken.sub"
            v-model="data.item.enabled"
            name="check-button"
            switch
            size="lg"
            @change="onEnabledChange({ id: data.item.id, enabled: data.item.enabled })"
            >
          </b-form-checkbox>
        </template>
        <template #cell(actions)="data">
          <b-dropdown right size="sm" text="Small" class="m-2" variant="link" no-caret>
            <template #button-content>
              <b-icon icon="three-dots" variant="secondary"></b-icon><span class="sr-only">Actions</span>
            </template>
            <b-dropdown-item :to="`/users/${data.item.id}`">Edit</b-dropdown-item>
            <b-dropdown-item-button @click.prevent="confirmDelete(data.item)">Delete</b-dropdown-item-button>
          </b-dropdown>
        </template>
      </b-table>
      <b-card-footer>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="my-0"
        ></b-pagination>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
import JsonCSV from 'vue-json-csv'
import { mapGetters, mapState, mapActions } from 'vuex'

export default {
  components: {
    'download-json-csv': JsonCSV,
  },
  data() {
    return {
      userTableFields: [
        // { key: 'employee_id', sortable: true, tdClass: 'align-middle text-nowrap', thClass: 'text-nowrap' },
        { key: 'email', sortable: true, tdClass: 'align-middle', thClass: 'text-nowrap' },
        { key: 'last_name', sortable: true, tdClass: 'align-middle', thClass: 'text-nowrap' },
        { key: 'first_name', sortable: true, tdClass: 'align-middle', thClass: 'text-nowrap' },
        // { key: 'supervisor_id', label: 'Manager Id', sortable: true, tdClass: 'align-middle text-nowrap', thClass: 'text-nowrap' },
        { key: 'role', sortable: true, tdClass: 'align-middle', thClass: 'text-nowrap' },
        { key: 'last_login', sortable: true, tdClass: 'align-middle text-nowrap', thClass: 'text-nowrap' },
        { key: 'enabled', sortable: true, tdClass: 'align-middle td-shrink', thClass: 'text-nowrap' },
        { key: 'actions', label: '', tdClass: 'py-0 align-middle pl-0 pr-3 td-shrink', thClass: 'text-nowrap' }
      ],
      sortBy: null,
      sortDesc: false,
      sortDirection: 'asc',
      sortCompare: (itemA, itemB, key) => {
        if (['last_login'].indexOf(key) > -1) {
          let a = ((itemA[key] === null || itemA[key] === 'Never' || itemA[key] === 'Locked') ? '00/00/0000, 00:00 AM' : itemA[key]).split(',')
          let b = ((itemB[key] === null || itemB[key] === 'Never' || itemB[key] === 'Locked') ? '00/00/0000, 00:00 AM' : itemB[key]).split(',')
          let d1 = a[0].split('/')
          d1 = (parseInt(d1[2], 10) * 100000000) + (parseInt(d1[0], 10) * 1000000) + (parseInt(d1[1]) * 10000)
          let d2 = b[0].split('/')
          d2 = (parseInt(d2[2], 10) * 100000000) + (parseInt(d2[0], 10) * 1000000) + (parseInt(d2[1]) * 10000)
          let t1 = a[1].split(' ')
          t1 = (t1[2] === 'PM' && parseInt(t1[1].split(':')[0]) !== 12)
            ? (parseInt(t1[1].split(':')[0], 10) * 100) + parseInt(t1[1].split(':')[1]) + 1200
            : (parseInt(t1[1].split(':')[0], 10) * 100) + parseInt(t1[1].split(':')[1])
          let t2 = b[1].split(' ')
          t2 = (t2[2] === 'PM' && parseInt(t2[1].split(':')[0]) !== 12)
            ? (parseInt(t2[1].split(':')[0], 10) * 100) + parseInt(t2[1].split(':')[1]) + 1200
            : (parseInt(t2[1].split(':')[0], 10) * 100) + parseInt(t2[1].split(':')[1])
          a = d1 + t1
          b = d2 + t2
          return a - b
        } else {
          return false
        }
      },
      tableBusy: false,
      totalRows: 0,
      currentPage: 1,
      perPage: 25,
      pageOptions: [25, 50, 100],
      filter: null,
      // download-json-csv
      csvItems: [],
      csvFileName: 'UserList.csv',
      csvHeader: {
        first_name: 'First Name',
        last_name: 'Last Name',
        email: 'E-Mail',
        location: 'Location',
        employee_id: 'Employee ID',
        supervisor_id: 'Manager ID',
        enabled: 'Enabled',
        languages: 'Languages',
        profiles: 'Sender Profiles',
        role: 'Role',
        last_login: 'Last Login',
        reporting_users: 'Reporting Users',
      },
      csvFieldsToInclude: ['first_name', 'last_name', 'email', 'location', 'employee_id', 'supervisor_id', 'last_login', 'enabled', 'languages',
        'profiles', 'role', 'reporting_users'],
    }
  },
  computed: {
    ...mapState('admin', [
      'roles',
      'globalClientSelected',
    ]),
    ...mapGetters('admin', [
      'getLabel',
    ]),
    ...mapGetters('ping', [
      'parsedAccessToken',
      'manageableUsers',
    ]),
    userTableItems() {
      return this.manageableUsers.map(user => {
        const {
          // createdAt,
          email,
          enabled,
          // environment,
          id,
          // lastSignOn,
          // lifecycle,
          // locale,
          // mfaEnabled,
          name,
          // nickname,
          // population,
          // preferredLanguage,
          // primaryPhone,
          // profile,
          // salesforceCustomerID,
          salesforceUserData,
          locked,
          // timezone,
          // title,
          // updatedAt,
          // username
          // _links
        } = user;
        const item = {
          id,
          employee_id: user?.profile?.employeeID,
          first_name: name?.given,
          last_name: name?.family,
          email,
          supervisor_id: user?.profile?.supervisorID,
          location: user?.profile?.location,
          reporting_users: user?.profile?.reportingUsers?.join(';'),
          role: user?.profile?.role,
          last_login: locked?.status ? 'Locked' : user?.lastSignOn?.at ? this.formatUTCtoLocal(user?.lastSignOn?.at) : 'Never',
          enabled,
          locked: locked?.status,
          languages: salesforceUserData?.languages?.join(';'),
          profiles: salesforceUserData?.sender_profiles?.join(';'),
        };
        return item;
      })
    },
    active() {
      return ((this.userTableItems.filter(user => user.enabled))?.length) || 0
    },
    inActive() {
      return ((this.userTableItems.filter(user => !user.enabled))?.length) || 0
    }
  },
  methods: {
    ...mapActions('ping', [
      'fetchIDAUsers',
      'toggleUserEnabled',
      'deleteUser'
    ]),
    editUser(id) {
      console.log(id);
    },
    confirmDelete(user) {
      this.$bvModal.msgBoxConfirm(`Are you sure you want to delete ${user.email}?`, {
        title: 'Confirm delete user',
        // size: 'sm',
        // buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        // footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(value => {
          if (value) {
            this.tableBusy = true;
            return this.deleteUser(user.id);
          }
        })
        .then(() => {
          this.initializeTable()
          console.log('deleted');
        })
        .catch(err => {
          console.log(err);
          // An error occurred
        })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
      this.csvItems = filteredItems // Adding this to update the download json csv fields
    },
    onEnabledChange({ id, enabled }) {
      const payload = {
        userId: id,
        data: {
          enabled: enabled
        }
      };
      this.toggleUserEnabled(payload);
    },

    initializeTable() {
      this.tableBusy = true;
      this.fetchIDAUsers()
        .then(() => {
          this.tableBusy = false
          this.totalRows = this.manageableUsers.length
          this.csvItems = this.userTableItems
        })
        .catch(error => {
          console.log(error)
        })
    },

    formatUTCtoLocal(utc) {
      return (new Date(utc))?.toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' })
    },
  },
  mounted() {
    this.initializeTable()
  },

  watch: {
    globalClientSelected() {
      this.initializeTable()
    }
  },
}
</script>
